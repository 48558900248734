import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import lobaLogo from '../../images/loba-dark.svg';

function NotFound() {
	window.moolt.loading.hide(function () {
		document.querySelector('body').classList.remove('loaded');
	});
	return (
		<div className="text-center">
			<img src={lobaLogo} alt="" />
			<h1 className="display">Page not found</h1>
			<p className="lead">The page you are looking for was not found. Go back to homepage.</p>
			<Link to="/" className="btn btn-primary">Homepage</Link>
		</div>
	)
}

export default NotFound;