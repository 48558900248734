export const changeLang = (state, { payload }) => ({
  ...state,
  currentLang: payload.lang,
});

export const fetchAvailableLangStart = (state, { payload }) => ({
  ...state,
  loading: payload.loading,
  availableLangsStart: true,
  availableLangsEnd: false,
});


export const fetchAvailableLangSuccess = (state, { payload }) => ({
  ...state,
  availableLangs: payload.availableLangs,
  availableLangsStart: false,
  availableLangsEnd: true,
});


export const fetchSiteStart = (state, { payload }) => ({
  ...state,
  loading: payload.loading,
  startFetchData: true,
  endFetchData: false,
});

export const fetchSiteSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  pageContent: { ...payload.pageContent },
  menus: payload.menus,
  configs: payload.configs,
  startFetchData: false,
  endFetchData: true,
});