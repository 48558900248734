import React from 'react';
import PropTypes from 'prop-types';

export default function About({ data, currentLang }) {
	return (
		<section>
			<div id={data.relatedarticle.pretitle_langs[currentLang]} className="container">
				<div className="theme-title-three mb-200">
					<h2 className="title">{data.relatedarticle.title_langs[currentLang]} <i className="moolt-symbol"></i></h2>
					<div dangerouslySetInnerHTML={{ __html: data.relatedarticle.body_langs[currentLang] }}></div>
				</div>
			</div>
		</section>
	);
}

About.propTypes = {
	data: PropTypes.object,
	currentLang: PropTypes.string,
}