import React from 'react';
import { hydrate, render } from 'react-dom';
//import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';


import App from './App';
/*if(process.env.REACT_APP_GTMANAGER_ID !== 0){    
    const tagManagerArgs = {gtmId: process.env.REACT_APP_GTMANAGER_ID}; 
    TagManager.initialize(tagManagerArgs);
}
else if (process.env.REACT_APP_GANALYTICS_ID !== 0){*/

    ReactGA.initialize(process.env.REACT_APP_GANALYTICS_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
//}

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
	hydrate(<App />, rootElement);
} else {
	render(<App />, rootElement);
}