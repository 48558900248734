import * as global from './global';
import * as types from '../actions/types';

const createReducer = handlers => (state, action) => {
  if (!Object.prototype.hasOwnProperty.call(handlers, action.type)) {
    return state;
  }

  return handlers[action.type](state, action);
};

export default createReducer({
  [types.CHANGE_LANG]: global.changeLang,
  [types.FETCH_AVAILABLE_LANG_START]: global.fetchAvailableLangStart,
  [types.FETCH_AVAILABLE_LANG_SUCCESS]: global.fetchAvailableLangSuccess,
  [types.FETCH_SITE_START]: global.fetchSiteStart,
  [types.FETCH_SITE_SUCCESS]: global.fetchSiteSuccess,
});