import React from 'react';
import PropTypes from 'prop-types';

function RelatedArticles({ relatedArticles, currentLang }) {

	function extractTitle(subBlock, currentLang) {
		return (subBlock && subBlock.relatedarticle && subBlock.relatedarticle.title_langs && subBlock.relatedarticle.title_langs[currentLang]) || '';
	}

	function extractBody(subBlock, currentLang) {
		return (subBlock && subBlock.relatedarticle && subBlock.relatedarticle.body_langs && subBlock.relatedarticle.body_langs[currentLang]) || '';
	}

	return (
		<React.Fragment>
			{
				relatedArticles.map((subBlock, key) => {
					return (
						<div className="col-lg-4 col-sm-6 col-12 footer-list" key={key}>
							<h5 className="title">{extractTitle(subBlock, currentLang)}</h5>
							<div dangerouslySetInnerHTML={{ __html: extractBody(subBlock, currentLang) }}></div>
						</div>
					);
				})
			}
		</React.Fragment>
	)
}

RelatedArticles.propTypes = {
	relatedArticles: PropTypes.array,
	currentLang: PropTypes.string,
}

export default function Contacts({
	data,
	currentLang
}) {

	const footerId = (data && data.relatedarticle && data.relatedarticle.pretitle_langs && data.relatedarticle.pretitle_langs[currentLang]) || '';

	return (
		<footer id={footerId} className="theme-footer-one p0">
			<div className="top-footer">
				<div className="container">
					<div className="row text-center">
						<RelatedArticles
							currentLang={currentLang}
							relatedArticles={(data && data.relatedarticle && data.relatedarticle.relatedarticles) || []}
						/>
					</div>
				</div>
			</div>
			<br /><br />
			<div className="text-center">
				<a href="https://www.loba.pt/pt/" target="_blank" rel="noopener noreferrer" title="LOBA.cx" className="loba">
					<img src="/images/loba-logo.svg" alt="LOBA.cx logo" />
				</a>
			</div>
		</footer>
	);
}

Contacts.propTypes = {
	data: PropTypes.object,
	currentLang: PropTypes.string,
}