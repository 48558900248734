import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { HomePage, NotFound } from './Pages';
import api from './services/api';
import { useStore } from './store';
import { fetchAvailableLangStart, fetchAvailableLangSuccess, fetchAvailableLangError } from './store/actions';

const Routes = () => {
	const [{ availableLangs, loading, availableLangsStart, availableLangsEnd }, dispatch] = useStore();

	async function fetchAvailableLangs() {
		dispatch(fetchAvailableLangStart());
		try {
			const { data } = await api.get('/languages');
			dispatch(fetchAvailableLangSuccess(data.languages));
		} catch (error) {
			dispatch(fetchAvailableLangError(error))
		}
	}

	useEffect(() => {
		fetchAvailableLangs();
	}, [])

	return (
		<React.Fragment>
			{availableLangs.length && (
				<BrowserRouter>
					<Switch>
						<Route path="/" exact component={() => <Redirect to={`/${localStorage.getItem('app-lang') || 'pt'}/`} />} />
						<Route path="/notFound" exact component={NotFound} />
						<Route path="/:lang/" exact component={HomePage} />
					</Switch>
				</BrowserRouter>
			)}
		</React.Fragment>
	);
};

export default Routes;