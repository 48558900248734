import React from 'react';
import PropTypes from 'prop-types';

export default function Solutions({ data, currentLang, }) {

	return (
		<section>
			<div id={data
				&& data.relatedarticle
				&& data.relatedarticle.pretitle_langs
				&& data.relatedarticle.pretitle_langs[currentLang]} className="section-portfo our-service-portfo">
				<div className="container">
					<div className="title-wrapper">
						<h2 className="theme-title-two">{data && data.relatedarticle && data.relatedarticle.title_langs && data.relatedarticle.title_langs[currentLang]} <i className="moolt-symbol"></i></h2>
						<div className="row">
							<div className="col-sm-10 col-md-7 col-lg-5">
								<div dangerouslySetInnerHTML={{ __html: data && data.relatedarticle && data.relatedarticle.body_langs && data.relatedarticle.body_langs[currentLang] }}></div>
							</div>
						</div>
					</div>

					{/* Blocks */}
					<div className="row">
						{
							((data && data.relatedarticle && data.relatedarticle.relatedarticles) || []).map((subBlock, key) => {
								return (
									<div className="col-lg-6 service-desc-column" key={key} data-aos="flip-right">
										<div className="service-block">
											<img src="/images/moolt-symbol.svg" alt="" className="icon" width="40" height="20" />
											<h5 className="title">{subBlock && subBlock.relatedarticle && subBlock.relatedarticle.title_langs && subBlock.relatedarticle.title_langs[currentLang]}</h5>
											<div dangerouslySetInnerHTML={{ __html: subBlock && subBlock.relatedarticle && subBlock.relatedarticle.body_langs && subBlock.relatedarticle.body_langs[currentLang] }}></div>
										</div>
									</div>
								);
							})
						}
					</div>

				</div>
			</div>
		</section>
	);
}

Solutions.propTypes = {
	data: PropTypes.object,
	currentLang: PropTypes.string,
}