import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import Helmet from 'react-helmet';
import { api } from '../../services'

import { useParams, useHistory } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';

import { Header } from '../../components'

import MetaTags from './MetaTags';
import PageBlocks from './PageBlocks';

import { useStore } from '../../store';
import { fetchSiteStart, fetchSiteSuccess, fetchSiteError, changeLang } from '../../store/actions';

const Home = () => {
	const [{
		loading,
		availableLangs,
		startFetchData,
		endFetchData,
		pageContent,
		currentLang,
		menus,
		configs,
	}, dispatch] = useStore();

	const { lang } = useParams();
	const history = useHistory();

	async function fetchSiteData(lang) {
		dispatch(fetchSiteStart());
		try {
			const { data: pageContent } = await api.post(`/pagecontent`, {
				lang: lang,
				route: 'homepage'
			});
			const menus = await api.get(`/menutree/${currentLang}/menu_principal`);
			const configs = await api.get(`/config/all`);

			dispatch(fetchSiteSuccess(pageContent, menus, configs));
			AOS.init({
				duration: 1000,
				mirror: true
			});
		} catch (error) {
			dispatch(fetchSiteError(error))
		}
	}

	useEffect(() => {
		if (loading) {
			return window.moolt.loading.show(function () {
				document.querySelector('body').classList.remove('loaded');
			});
		}
		return window.moolt.loading.hide(function () {
			document.querySelector('body').classList.add('loaded');
		});
	}, [loading])

	useEffect(() => {
		fetchSiteData(currentLang);
	}, [currentLang])

	useEffect(() => {
		const existsLanguage = !!availableLangs.find(language => {
			return (language.shortname === lang);
		})

		if (existsLanguage) {
			return window.moolt.loading.show(function () {
				dispatch(changeLang(lang));
				localStorage.setItem('app-lang', lang);
			})
		}

		history.push('/notFound');
	}, [lang])

	function handleOnChangeLanguage(language) { }

	return (
		<React.Fragment>
			<Header languages={availableLangs} menus={menus} configs={configs} currentLang={currentLang} onChangeLanguage={handleOnChangeLanguage} />
                        <Helmet htmlAttributes={{ lang : currentLang}}/>
			<MetaTags metaInfo={(pageContent && pageContent.routeInfo && [pageContent.routeInfo]) || []} currentLang={currentLang} />
			<PageBlocks blocks={(pageContent
				&& pageContent.pageContent
				&& pageContent.pageContent.article
				&& pageContent.pageContent.article[0]
				&& pageContent.pageContent.article[0].relatedarticles) || []} currentLang={currentLang} />
		</React.Fragment>
	);
};

export default Home;