
import * as types from './types';

export const changeLang = lang => ({ type: types.CHANGE_LANG, payload: { lang } });

export const fetchAvailableLangStart = () => ({ type: types.FETCH_AVAILABLE_LANG_START, payload: { loading: true } });
export const fetchAvailableLangSuccess = (availableLangs) => ({ type: types.FETCH_AVAILABLE_LANG_SUCCESS, payload: { availableLangs } });
export const fetchAvailableLangError = (error) => ({ type: types.FETCH_AVAILABLE_LANG_ERROR, payload: { error } });

export const fetchSiteStart = () => ({ type: types.FETCH_SITE_START, payload: { loading: true } });
export const fetchSiteSuccess = (pageContent, menus, configs) => ({ type: types.FETCH_SITE_SUCCESS, payload: { pageContent, menus, configs } });
export const fetchSiteError = (error) => ({ type: types.FETCH_SITE_ERROR, payload: { error } });