import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import * as config from '../../configs';

export default function Portfolio({ data, currentLang }) {
	const portfolioWorks = [];

	let $gallery;

	useEffect(() => {
		$gallery = window.myIsotopeGallery();
	}, []);

	function onLoadImage() {
		$gallery && $gallery.isotope({ filter: '*' });
	}

	return (
		<section>
			<div id={data && data.relatedarticle && data.relatedarticle.pretitle_langs && data.relatedarticle.pretitle_langs[currentLang]} className="section-portfo our-project-portfo">
				<div className="container">
					<div className="title-wrapper">
						<div className="row">
							<div className="col-md-8 col-lg-5">
								<h2 className="theme-title-two">{data && data.relatedarticle && data.relatedarticle.title_langs && data.relatedarticle.title_langs[currentLang]} <i className="moolt-symbol"></i></h2>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-10 col-md-7 col-lg-5">
								<div dangerouslySetInnerHTML={{ __html: data && data.relatedarticle && data.relatedarticle.body_langs && data.relatedarticle.body_langs[currentLang] }}></div>
							</div>
						</div>
					</div>

					<ul className="isotop-menu-wrapper hide-pr">
						{/* <li className="is-checked" data-filter="*">Todos.</li> */}
						{
							((data && data.relatedarticle && data.relatedarticle.relatedarticles) || []).map((subBlock, key) => {
								((subBlock && subBlock.relatedarticle && subBlock.relatedarticle.relatedarticles) || []).forEach(relatedarticleSubBlock => {
									let alreadyExists = false;
									portfolioWorks.forEach(item => {
										if (item.related_article_id === relatedarticleSubBlock.related_article_id) {
											alreadyExists = true;
										}
									});
									if (alreadyExists === false) {
										portfolioWorks.push(relatedarticleSubBlock);
									}
								});

								return (
									<li
										key={key}
										className={key === 0 ? 'is-checked' : null}
										data-filter={`.${((subBlock && subBlock.relatedarticle && subBlock.relatedarticle.title) || '').toLowerCase()}`}
									>
										{subBlock && subBlock.relatedarticle && subBlock.relatedarticle.title_langs && subBlock.relatedarticle.title_langs[currentLang]}.
									</li>
								)
							})
						}
					</ul>
				</div>

				<div id="isotop-gallery-wrapper">
					<div className="grid-sizer"></div>
					{
						portfolioWorks.map((work, key) => {
							let projectCategories = ((work && work.relatedarticle && work.relatedarticle.mainarticles) || []).map(mainarticle => mainarticle.mainarticle.title);
							return (
								<div key={key} className={`isotop-item ${projectCategories.join(' ').toLowerCase()}`}>
									<div className="effect-zoe">
										<img onLoad={onLoadImage} src={`${config.IMGS_URL}/img/380/420/1/${work && work.relatedarticle && work.relatedarticle.image}`} alt={work && work.relatedarticle && work.relatedarticle.title_langs && work.relatedarticle.title_langs[currentLang]} />
										<div className="inner-caption">
											<h2>{work && work.relatedarticle && work.relatedarticle.title_langs && work.relatedarticle.title_langs[currentLang]}</h2>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
		</section >
	);
}

Portfolio.propTypes = {
	data: PropTypes.object,
	currentLang: PropTypes.string,
}