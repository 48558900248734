/* eslint-disable no-useless-constructor */
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

export default function Header({
	languages,
	menus,
	configs,
	currentLang,
	onChangeLanguage,
}) {
	//document.querySelector('body').classList.add('menu-loaded');

	const menuData = (menus && menus.data && menus.data.menutree) || [];
	const linkedin = (configs && configs.data && configs.data.configs && configs.data.configs[0]) || {};

	/**
	 * @param {Object} menuItem - The Menu item.
	 * E.g.: {"id":1,"title_langs":{"pt":"Sobre Nós","en":"About","es":"Sobre Nosotros"},"link_langs":{"pt":"#sobre-nos","en":"#about","es":"#sobre-nosotros"},"slug_langs":[],"is_link":1,"new_window":0,"active_langs":{"pt":"1","en":"1","es":"1"},"published_at":null,"unpublished_at":null,"children":[]}
	 * @param {string} currentLang - The current Language
	 * E.g.: pt
	 * 
	 * @returns {string} - String with tag.
	 * E.g.: #sobre-nos
	 */
	function extractMenuLink(menuItem, currentLang) {
		return (menuItem && menuItem.link_langs && menuItem.link_langs[currentLang]) || '';
	}

	function extractMenuTitle(menuItem, currentLang) {
		return (menuItem && menuItem.title_langs && menuItem.title_langs[currentLang]) || '';
	}

	function toggleMenuonMobile() {
		if ( document.documentElement.clientWidth < 991 ) {
			document.querySelector('.navbar-toggler').classList.toggle('collapsed');
			var aria = document.querySelector('.navbar-toggler');
			aria.setAttribute( 'aria-expanded', aria.getAttribute('aria-expanded') === 'false' ? 'true' : 'false' );
			document.querySelector('.navbar-collapse').classList.toggle('show');
		}
	}

	return (
		<div className="theme-main-menu">
			<div className="shrt-menu shrt-menu-three">
				<div className="container">
					<div className="d-flex justify-content-between align-items-center main-header">
						<div className="logo order-2">
							<a href="/">
								<img src="/images/moolt-logo.svg" alt="" />
							</a>
						</div>
						<nav id="mega-menu-holder" className="navbar navbar-expand-lg order-1">
							<div className="nav-container">
								<button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent33" aria-controls="navbarSupportedContent33" aria-expanded="false" aria-label="Toggle navigation">
									<i className="flaticon-setup"></i>
								</button>
								<div className="collapse navbar-collapse" id="navbarSupportedContent33">
									<ul className="navbar-nav">
										{
											menuData.map((menuItem, key) => (
												<li className="nav-item position-relative" key={key} onClick={() => { toggleMenuonMobile(); }}>
													<AnchorLink
														className="nav-link scroll-target"
														title={extractMenuTitle(menuItem, currentLang)}
														offset={document.querySelector('.theme-main-menu .container') && document.querySelector('.theme-main-menu .container').offsetHeight + 90}
														href={extractMenuLink(menuItem, currentLang)}
													>
														{extractMenuTitle(menuItem, currentLang)}
													</AnchorLink>
												</li>)
											)
										}
									</ul>
								</div>
							</div>
						</nav>

						<div className="widget-container order-3 header-right">
							<ul className="d-flex align-items-center">
								<li>
									{linkedin.value !== '' ? <a href={linkedin.value} title={linkedin.title} target="_blank" rel="noopener noreferrer" className="header-linkedin"><img src="/images/linkedin.svg" alt="Linkedin logo" /></a> : null}
								</li>
								<li className="language-switcher">
									<div className="dropdown">
										<button type="button" className="dropdown-toggle" data-toggle="dropdown">
											{currentLang}
										</button>
										<div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', transform: 'translate3d(-91px, 55px, 0px)', top: '0px', left: '0px', willChange: 'transform' }}>
											<ul className="ct-language__dropdown">
												{languages.map((lang, key) => <li key={key}> <Link to={`/${lang.shortname}/`} onClick={() => onChangeLanguage(lang.shortname)} className="lang-select" >{lang.title}</Link></li>)}
											</ul>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div >
	)
}

Header.propTypes = {
	menus: PropTypes.object,
	configs: PropTypes.object,
	languages: PropTypes.array,
	currentLang: PropTypes.string,
	onChangeLanguage: PropTypes.func,
}

Header.defaultProps = {

}
