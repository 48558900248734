import React from 'react';
import PropTypes from 'prop-types';

export default function Banner({
	data,
	currentLang
}) {

	/**
	 * 
	 * @example
	 * const claim = 'AAAA BBBB';
	 * const [claimBegin, claimEnd] = extractBeginAndEndClaim(claim);
	 * 
	 * @param {string} claim - The claim.
	 * 
	 * @returns {Array} - Array with begin and end claim.
	 * E.g.: ['AAAA', 'BBBB'];
	 */
	function extractBeginAndEndClaim(claim) {
		const claimBegin = claim.substring(0, claim.lastIndexOf(' '));
		const claimSplit = claim.split(' ');
		const claimEnd = claimSplit[claimSplit.length - 1];

		return [claimBegin, claimEnd];
	}

	// Main Claim 
	const mainClaim = ((data && data.relatedarticle && data.relatedarticle.title_langs && data.relatedarticle.title_langs[currentLang]) || '').trim();
	const [claimBegin, claimEnd] = extractBeginAndEndClaim(mainClaim);

	// Secondary Claim
	const secondaryClaim = ((data && data.relatedarticle && data.relatedarticle.subtitle_langs && data.relatedarticle.subtitle_langs[currentLang]) || '').trim();
	const [secondaryClaimBegin, secondaryClaimEnd] = extractBeginAndEndClaim(secondaryClaim);

	return (
		<div id="theme-banner-three">
			<div className="container">
				<div className="main-wrapper">
					<h1 className="main-title wow fadeInUp animated" data-wow-delay="0.2s">
						<span className="cd-headline clip">
							<span>{claimBegin}</span>
							<br />
							<span className="cd-words-wrapper">
								<b className="is-visible"></b>
								<b>{claimEnd} <i className="moolt-symbol moolt-symbol--big"></i></b>
							</span>
						</span>
					</h1>
				</div>
			</div>
			<div className="main-banner-slogan">
				<div className="container">
					<div className="row">
						<div className="col-8">
							<div className="molding-tools">
								<span>{secondaryClaimBegin}</span>
								<span>{secondaryClaimEnd}</span>
							</div>
						</div>
						<div className="col-4">
							<div className="main-banner-dots">
								<span></span>
								<span></span>
								<span></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

Banner.propTypes = {
	data: PropTypes.object,
	currentLang: PropTypes.string
}