import axios from 'axios';
import * as config from '../configs';

const instance = axios.create({
	baseURL: config.API_URL,
	headers: {
		'Content-Type': config.API_CONTENT_TYPE,
		'Authorization': config.API_AUTHORIZATION,
	}
});

export default instance;