import React from 'react';
import Helmet from 'react-helmet';

const MetaTags = ({ metaInfo }) => metaInfo.map((meta, key) => (
  <Helmet key={key}>
    <title>{meta.metadata.title}</title>
    <meta name="description" content={meta.metadata.description} />
    {/* Facebook Meta Tags */}
    {meta.opengraph.type ? <meta property="og:type" content={meta.opengraph.type} /> : null}
    <meta property="og:title" content={meta.metadata.title ? meta.metadata.title : null} />
    <meta property="og:description" content={meta.metadata.description ? meta.metadata.description : null} />
    {meta.opengraph.image && meta.opengraph.image !== 'undefined' ? <meta property="og:image" content={meta.opengraph.image} /> : null}
    {/* Twitter Meta Tags */}
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:title" content={meta.metadata.title ? meta.metadata.title : null} />
    <meta property="twitter:description" content={meta.metadata.description ? meta.metadata.description : null} />
    {meta.twitter_card.image && meta.twitter_card.image !== 'undefined' ? <meta property="twitter:image" content={meta.twitter_card.image} /> : null}
  </Helmet>
));

export default MetaTags;