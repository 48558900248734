import React from 'react';
import PropTypes from 'prop-types';

export default function Process({
	data,
	currentLang,
}) {
	return (
		<section>
			<div className="section-portfo skill-section-portfo">
				<div className="container">
					<div className="title-wrapper">
						<div className="row">
							<div className="col-md-8 col-lg-5">
								<h2 className="theme-title-two">{data && data.relatedarticle && data.relatedarticle.title_langs && data.relatedarticle.title_langs[currentLang]} <i className="moolt-symbol"></i></h2>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-10 col-md-7 col-lg-5">
								<div dangerouslySetInnerHTML={{ __html: data && data.relatedarticle && data.relatedarticle.body_langs && data.relatedarticle.body_langs[currentLang] }}></div>
							</div>
						</div>
					</div>

					<div className="row">
						{
							((data && data.relatedarticle && data.relatedarticle.relatedarticles && data.relatedarticle.relatedarticles) || []).map((subBlock, key) => {
								const percentRelatedArticles = ((data && data.relatedarticle && data.relatedarticle.relatedarticles) || []).length * 100;
								return (
									<div className="col-lg-6" key={key}>
										<div className="skill-bar">
											<div className="progress">
												<div className="progress-bar" data-percent={key + 1} style={{ width: `${(key + 1) / percentRelatedArticles}%` }}>
													<span className="percent-text"><span className="count">{key + 1 > 9 ? key + 1 : '0' + (key + 1)}</span></span>
												</div>
											</div>
											<div className="skill-title">{subBlock && subBlock.relatedarticle && subBlock.relatedarticle.title_langs && subBlock.relatedarticle.title_langs[currentLang]}</div>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
		</section>
	);
}

Process.propTypes = {
	data: PropTypes.object,
	currentLang: PropTypes.string,
}