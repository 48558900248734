import React from 'react';
import Routes from './Routes';

import { StoreProvider } from './store';
import reducers from './store/reducers';
import initialState from './store/initialState';

const App = () => (
  <StoreProvider initialState={initialState} reducer={reducers}>
    <Routes />
  </StoreProvider>
);

export default App;