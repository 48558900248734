export default {
  loading: true,
  availableLangsStart: false,
  availableLangsEnd: false,
  currentLang: localStorage.getItem('app-lang') || 'pt',
  startFetchData: false,
  endFetchData: false,
  availableLangs: [],
  pageContent: {},
  menus: {},
  configs: {},
};