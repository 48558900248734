import React from 'react';
import {
  About,
  Banner,
  Solutions,
  Process,
  Portfolio,
  Contacts
} from '../../components'

const PageBlocks = ({ blocks, currentLang }) => {
  const mapperComponents = {
    Banner,
    About,
    Solutions,
    Process,
    Portfolio,
    Contacts,
  }

  function getComponent(blockName, block, key) {
    const hasComponent = Object.prototype.hasOwnProperty.call(mapperComponents, blockName);
    if (!hasComponent) {
      return null;
    }

    const Component = mapperComponents[blockName];
    return <Component data={block} currentLang={currentLang} key={key} />
  }

  return blocks.map((block, key) => {
    const layoutName = block
      && block.relatedarticle
      && block.relatedarticle.layout
      && block.relatedarticle.layout.name;

    return getComponent(layoutName, block, key)
  });
};

export default PageBlocks;